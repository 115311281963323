<template>
    <div v-if="estimatedDeliveryDate.message||estimatedDeliveryDate.loading||estimatedDeliveryDate.error">
        <span v-if="estimatedDeliveryDate.loading" style="opacity: 0"><span
            class="icon-spinner animate-spin"></span></span>
        <span v-else-if="estimatedDeliveryDate.message">{{estimatedDeliveryDate.message}}</span>
        <span v-else-if="estimatedDeliveryDate.error">{{estimatedDeliveryDate.error}}</span>
    </div>
</template>

<script>
    export default {
        name: "ProductsProductEstimatedShipDate",
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {

            return {
                estimatedDeliveryDate: {
                    message: null,
                    loading: false,
                    error: false
                }
            }
        },
        methods: {
            loadEstimatedDeliveryDate() {

                this.estimatedDeliveryDate.loading = true;

                const request = {
                    country: this.$store.getters["user/profile/country"],
                    items: [{
                        sku: this.value.sku,
                        tag: this.value.tag
                    }]
                };

                this.$platform.post("/api/v2/grouping/products/estimated-ship-date", request)
                    .then(response => {

                        const result = response.data.results.find(item => item.sku == this.value.sku);

                        this.estimatedDeliveryDate.message = result && result.estimated_ship_date
                            ? result.estimated_ship_date.message
                            : "";
                    })
                    .catch(error => this.estimatedDeliveryDate.error = error.toString())
                    .finally(() => this.estimatedDeliveryDate.loading = false);
            }
        },
        watch: {
            value: {
                deep: true,
                immediate: true,
                handler(product) {

                    if (product.estimated_ship_date && null !== product.estimated_ship_date.message) {

                        this.estimatedDeliveryDate.message = product.estimated_ship_date.message
                            ? product.estimated_ship_date.message
                            : null;
                        this.estimatedDeliveryDate.loading = false;
                    } else if (product.estimated_ship_date && product.estimated_ship_date.error) {

                        this.estimatedDeliveryDate.error = product.estimated_ship_date.error
                            ? product.estimated_ship_date.error
                            : null;
                        this.estimatedDeliveryDate.loading = false;
                    } else if (product.estimated_ship_date && product.estimated_ship_date.loading) {

                        this.estimatedDeliveryDate.loading = true;
                    } else {

                        this.loadEstimatedDeliveryDate();
                    }
                }
            }
        }
    }
</script>
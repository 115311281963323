import {createI18n} from "vue-i18n"
import enUS from "./locales/en-US"
import ptBR from "./locales/pt-BR"
import fr from "./locales/fr"
import es from "./locales/es"
import pt from "./locales/pt"
import hu from "./locales/hu"
import it from "./locales/it"
import cs from "./locales/cs"
import de from "./locales/de"
import nl from "./locales/nl"
import pl from "./locales/pl"
import datetimeFormats from "./datetimeFormats"

export function setupI18n(locale = "en-US") {

    const i18n = createI18n({
        locale,
        warnHtmlInMessage: "off",
        fallbackLocale: {
            "en-US": ["en-US"],
            "pt-BR": ["pt-BR", "en-US"],
            "pt": ["pt","en-US"],
            "fr": ["fr", "en-US"],
            "es": ["es", "en-US"],
            "hu": ["hu", "en-US"],
            "it": ["it", "en-US"],
            "cs": ["cs", "en-US"],
            "de": ["de", "en-US"],
            "nl": ["nl", "en-US"],
            "pl": ["pl", "en-US"]
        },
        messages: {
            "en-US": enUS,
            "pt-BR": ptBR,
            "pt": pt,
            "fr": fr,
            "es": es,
            "hu": hu,
            "it": it,
            "cs": cs,
            "de": de,
            "nl": nl,
            "pl": pl
        },
        datetimeFormats,
        silentTranslationWarn: true
    });

    setI18nLanguage(i18n, locale);

    return i18n
}

export function setI18nLanguage(i18n, locale) {

    i18n.global.locale = locale;
}
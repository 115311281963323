<template>
    <div class="products-page panel">
        <div class="row">

            <div class="col-12">
                {{brand}}

                <div class="h3">
                    <div class="float-right pl-4 pb-2">
                        <button v-if="displayAdd" type="button" class="btn btn-sm btn-primary" :disabled="disableAdd"
                                @click="add">
                            {{t('buttons.cart.add')}}
                        </button>
                        <button v-if="displayRemove" type="button" class="btn btn-sm btn-outline-primary"
                                @click="cart_remove(value)">
                            {{t('buttons.cart.remove')}}
                        </button>
                    </div>

                    {{name}}
                </div>

                <products-product-estimated-ship-date class="products-page-note pt-2 font-italic" :value="value"/>

                <div v-if="note" class="note" v-html="note"></div>
            </div>

            <div v-if="0 < value.pictures.length" class="col-12 offset-md-3 col-md-6 py-3">
                <products-product-thumbnails size="large" :value="value.pictures"/>
            </div>

            <div v-if="description" class="col-12">
                <h4>{{t('description')}}</h4>

                <p v-html="description"></p>
            </div>

            <!-- Widget item photo -->
            <img v-if="0 < value.pictures.length" :src="value.pictures[0].medium" :alt="name"
                 class="d-none"
                 :data-widget-item="widgetItem"/>
        </div>
    </div>
</template>

<script>

    import cart from "@/mixins/cart";
    import mixinConstraints from "@/mixins/constraints";
    import mixinProducts from "@/mixins/products";
    import {getTranslationValue} from "@/i18n/utils/lang_overrides";

    import ProductsProductThumbnails from "./ProductsProductThumbnails";
    import ProductsProductEstimatedShipDate from "@/components/products/product/ProductsProductEstimatedShipDate";

    export default {
        name: "ProductsProductPage",
        components: {
            ProductsProductThumbnails,
            ProductsProductEstimatedShipDate
        },
        mixins: [cart, mixinConstraints, mixinProducts],
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {

            return {
                t_path: "components.products.page"
            }
        },
        computed: {
            displayAdd() {

                return 0 < this.constraints_filterAvailableProducts([this.value], true).length
                    || 1 < this.constraints_getProductTypeAvailableAmount(this.value)
                    || !this.$store.getters["user/cart/exists"](this.value);
            },
            disableAdd() {

                return 0 === this.constraints_filterAvailableProducts([this.value], true).length;
            },
            displayRemove() {

                return 1 === this.constraints_getProductTypeAvailableAmount(this.value)
                    && !this.displayAdd
                    && this.$store.getters["user/cart/exists"](this.value);
            },
            brand() {

                return getTranslationValue(this.value.brand, ["name"], this.$i18n);
            },
            name() {

                return this.products_translateProductName(this.value);
            },
            description() {

                return this.products_translateProductDescription(this.value);
            },
            note() {

                return this.products_translateProductNote(this.value);
            },
            widgetItem() {

                return JSON.stringify({
                    item: this.value.sku
                })
            }
        },
        methods: {
            add() {

                this.$toast.success(this.t("added"));

                this.cart_add(this.value);
            }
        },
        mounted() {

            this.$store.dispatch("widget/setItem", this.value.sku)
        },
        unmounted() {

            this.$store.dispatch("widget/setItem", null)
        }
    }
</script>